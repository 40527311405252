<template>
    <empty-layout>
        <v-container>
            <navigation-back-button></navigation-back-button>
            <div class="text-h4 mb-5">Customize your plan</div>
            <v-row v-if="plans">
                <v-col lg="8">
                    <v-card class="pa-6" elevation="0" outlined>
                        <credit-card-details @changeCardInfo="handleChangeCardInfo" v-if="defaultPaymentMethod && defaultPaymentMethod.id" :prioritizeDetails="true"></credit-card-details>
                        <div v-else-if="!loadingElement && elementsOptions.clientSecret" style="width: 100%">
                            <stripe-element-payment 
                                :pk="publishableKey"
                                ref="paymentRef"
                                :elements-options="elementsOptions"
                                :confirmParams="confirmParams"
                            >
                            </stripe-element-payment>
                        </div>
                        <div v-if="loadingElement">
                            <v-progress-linear indeterminate color="primary"></v-progress-linear>
                        </div>
                    </v-card>
                </v-col>
                <v-col lg="4">
                    <v-card class="pa-6" elevation="0" outlined>
                        <div class="text-subtitle-1 mb-4">Your new plan</div>
                        <div class="d-flex justify-space-between align-center"> 
                            <div class="text-body-2">{{planName}}</div>
                            <div class="text-subtitle-2">{{planPrice}}</div>
                        </div>
                        <v-divider class="my-6"></v-divider>
                        <div class="d-flex justify-space-between">
                            <div>
                                <div class="text-subtitle-1">
                                    Total
                                </div>
                                <div class="text-body-1">
                                    (excl. tax)
                                </div>
                            </div>
                            <div class="text-subtitle-1">{{planPrice}}</div>
                        </div>
                        <responses-sync-preview  v-if="!isActivePlan" :planId="currentPlan.stripe_price.id"></responses-sync-preview>
                        <proration v-if="!isActivePlan" :price_plan="currentPlan.stripe_price.id"></proration>
                        <v-btn :loading="loadingPayment" :disabled="isActivePlan || loadingPayment || changingCardInfo" @click="subscribe" large color="primary" class="mt-6" elevation="0" width="100%">
                            {{subscription?'Confirm Changes': 'Confirm'}}
                        </v-btn>
                    </v-card>
                    <v-alert
                        v-if="!isActivePlan && isActiveFreeTrial"
                        outlined
                        type="success"
                        text
                        class="mt-4"
                    >
                        Upon purchase of <b>{{planName}} plan</b>, you will be charged right away. You will still have access to all features for the remaining <b>{{trialDaysPeriod}} days</b> of your free trial.
                    </v-alert>
                </v-col>
            </v-row>
        </v-container>
    </empty-layout>
</template>
<script>
import CreditCardDetails from '../billing/CreditCardDetails.vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { StripeElementPayment } from '@vue-stripe/vue-stripe';
import Proration from './Proration.vue'
import BillingApi from '../../api/BillingApi';
import EmptyLayout from '../../layouts/EmptyLayout.vue';
import NavigationBackButton from '../../layouts/NavigationBackButton.vue';
import ResponsesSyncPreview from '../billing/ResponsesSyncPreview.vue';
export default {
    name: 'Checkout',
    components: { CreditCardDetails, Proration, StripeElementPayment, EmptyLayout, NavigationBackButton, ResponsesSyncPreview},
    props: {
        planId: {
            required: true
        }
    },
    data(){
        return {
            loadingPayment: false,
            loadingElement: false,
            changingCardInfo: false,
            publishableKey: process.env.VUE_APP_STRIPE_KEY,
            elementsOptions: {
                appearance: {
                    theme: 'minimal',
                        variables: {
                            colorPrimary: '#0066FF',
                            colorBackground: '#ffffff',
                            colorText: '#212121',
                            colorDanger: '#F44336',
                            fontFamily: 'Poppins:wght@300;400;500;600;700&display=swap',
                            spacingUnit: '4px',
                            borderRadius: '4px',
                    }
                },
            },
            confirmParams: {
                return_url: window.location.href
            }
        }
    },
    computed: {
        ...mapGetters({
            plans: 'billing/plans',
            defaultPaymentMethod: 'payment/defaultPaymentMethod',
            subscription: 'billing/subscription',
            user: 'auth/user'
        }),
        isActiveFreeTrial(){
           return  !!this.user && !!this.user.trial_ends_at && this.$date().isBefore(this.user.trial_ends_at)
        },
        trialDaysPeriod(){
            if(this.isActiveFreeTrial){
                return Math.ceil(this.$date(this.user.trial_ends_at).diff(this.$date(), 'day', true))
            }
            return null
        },
        currentPlan(){

            // we allow passing planId or stripe_price.id since they have a completely different format its low chance they will mismatch
            const plan = this.plans && this.plans.find(plan =>{
                return plan.id === this.$route.params.planId || plan.stripe_price.id === this.$route.params.planId
                 
            })
            return plan
        },
        interval(){
            if(this.currentPlan.stripe_price){
                return this.currentPlan.stripe_price.recurring.interval
            }

            return this.currentPlan.interval
        },
        planName(){
            const interval = this.interval === 'year'? 'yearly' :'monthly'
            return `${this.currentPlan.name} (${interval})`
        },
        planPrice(){
           return `${this.currentPlan.stripe_price.unit_amount/100} USD`
        },
        isActivePlan(){
            return !!this.subscription && this.currentPlan.stripe_price.id === this.subscription.plan.id
        },
    },
    methods: {
        ...mapActions({
            loadPricePlans: 'billing/loadPricePlans',
            updateSubscription: 'billing/updateSubscription',
            loadPaymentMethods: 'payment/loadPaymentMethods'
        }),
        ...mapMutations(['showSnackBar']),
        goBack() {
            this.$router.go(-1)
        },
        async addPaymentMethod(){
            const setupRes = await this.$refs.paymentRef.stripe.confirmSetup({
                //`Elements` instance that was used to create the Payment Element
                elements: this.$refs.paymentRef.elements,
                redirect: 'if_required',
                confirmParams: {
                    return_url: window.location.href
                }
            });


            if(setupRes.error){
                this.showSnackBar({
                    text: setupRes.error.message || 'Wrong credit card information.', 
                    color: 'error', 
                    timeout: 2000
                })
            }else{
                const res = await BillingApi.addPaymentMethod(setupRes.setupIntent.payment_method)
                if(res && res.data){
                    this.loadPaymentMethods();
                    this.$gtag.event('add_payment_method');
                    window.heap.track('add_payment_method');
                    return res.data
                }
            }
        },
        async subscribe(){
            try {
                this.loadingPayment = true;

                let paymentMethod = null
                if(!this.defaultPaymentMethod){
                    paymentMethod = await this.addPaymentMethod()
                }else{
                    paymentMethod = this.defaultPaymentMethod
                }

                if(paymentMethod){
                    const res = await this.updateSubscription({
                        paymentMethodId: paymentMethod.id,
                        priceId: this.currentPlan.stripe_price.id
                    })
                    this.loadingPayment = false;
                    if(res && !res.error && res.data){

                        if(res.data.error){
                            return this.showSnackBar({
                                text: res.data.error, 
                                color: 'error', 
                                timeout: 4000
                            })
                        }

                        if(res.data.subscription_updated){
                            // Subscription Conversion
                            window.gtag && window.gtag('event', 'conversion', {
                                'send_to': 'AW-10927426629/uv0sCNbrosMDEMWYzdoo',
                                'value': this.currentPlan.stripe_price.unit_amount/100,
                                'currency': 'USD'
                            });

                            this.$gtag.purchase({
                                'value': this.currentPlan.stripe_price.unit_amount/100
                            });

                            window.heap.track('subscribe', {
                                plan: this.currentPlan.name,
                                price: this.currentPlan.stripe_price.unit_amount/100,
                                interval: this.currentPlan.stripe_price.recurring.interval
                            });

                            _cio && _cio.track('subscribe.success', {
                                plan: this.currentPlan.name,
                                price: this.currentPlan.stripe_price.unit_amount/100,
                                interval: this.currentPlan.stripe_price.recurring.interval
                            });

                            if(res.data.status === 'incomplete'){
                                this.showSnackBar({
                                    text: 'The payment is incomplete.', 
                                    color: 'warning', 
                                    timeout: 3000
                                })
                            }else{
                                this.showSnackBar({
                                    text: 'The payment is complete.', 
                                    color: 'success', 
                                    timeout: 2000
                                })
                            }

                            return this.$router.push({name: 'dashboard.billing'})
                        }
                    }else{
                        throw new Error(res.error)
                    }
                }else{
                    this.loadingPayment = false
                }
                
            } catch (error) {
                this.loadingPayment = false;
                console.error('subscribe', error);
                _cio && _cio.track('subscribe.failed')
                this.showSnackBar({
                    text: 'Failed transaction. Please check the credit card information or try another credit card.', 
                    color: 'error', 
                    timeout: 3000
                })
                
            }
            
        },
        async generatePaymentIntent () {
            try {
                this.loadingElement = true;
                const paymentIntent  = await BillingApi.loadIntentToken();
                this.elementsOptions = {...this.elementsOptions, clientSecret: paymentIntent.client_secret}
                this.loadingElement = false;
                this.$gtag.event('begin_checkout');
            } catch (error) {
                this.loadingElement = false;
                console.error('generatePaymentIntent', error);
            }
        },

        handleChangeCardInfo(value){
            this.changingCardInfo = value
        }
    },
    mounted(){
        this.loadPricePlans()
        if(!this.defaultPaymentMethod){
            this.generatePaymentIntent();
        }
        
        // Begin Checkout
        window.gtag && window.gtag('event', 'conversion', {
            'send_to': 'AW-10927426629/FPpjCK6XgcMDEMWYzdoo',
            'value': this.currentPlan && this.currentPlan.stripe_price.unit_amount/100 || 0,
            'currency': 'USD'
        });
    }
}
</script>