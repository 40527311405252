<template>
    <v-card v-if="loading || chargingMessage" class="d-flex mt-4 pa-4" elevation="0" color="grey lighten-5" min-height="100">
        <v-fade-transition hide-on-leave>
            <div v-if="loading" class="d-flex align-center justify-center flex-grow-1">
                <v-progress-circular
                    indeterminate
                    color="primary lighten-3"
                ></v-progress-circular>
            </div>
        </v-fade-transition>
        <v-fade-transition hide-on-leave>
            <div v-if="!loading" class="text-body-2" v-html="chargingMessage"></div>
        </v-fade-transition>
    </v-card>
</template>
<script>
import BillingApi from '../../api/BillingApi'
export default {
    name: 'Proration',
    props: ['price_plan'],
    data(){
        return {
            loading: false,
            previewInvoice: null
        }
    },
    async mounted(){
        try {
            this.loading = true
            const res = await BillingApi.previewInvoice(this.price_plan)
            this.loading = false
            if(res.data){
                this.previewInvoice = res.data
            }
        } catch (error) {
            this.loading = false
            console.error('proration calculation', error);
        }
    },
    computed: {
        chargingMessage(){
            if(this.previewInvoice && this.previewInvoice.lines && this.previewInvoice.lines.data && this.previewInvoice.lines.data.length){
                const filteredProrationItems = this.previewInvoice.lines.data.filter(line_item => line_item.proration === true)

                if(!filteredProrationItems || !filteredProrationItems.length){
                    return ''
                }
                const filteredStandardItems = this.previewInvoice.lines.data.filter(line_item => line_item.proration === false)
                
                let proratedAmount = 0
                let regularAmount = 0

                filteredProrationItems.forEach(line_item => {
                    proratedAmount += line_item.amount
                });

                proratedAmount = proratedAmount/100

                filteredStandardItems.forEach(line_item => {
                    regularAmount += line_item.amount
                })

                regularAmount = regularAmount/100

                const proratedPeriod = !!filteredProrationItems.length && this.$date(filteredProrationItems[0].period.end*1000).diff(filteredProrationItems[0].period.start*1000, 'day')
                const nextRegularPaymentDue = !!filteredStandardItems.length && this.$date(filteredStandardItems[0].period.start*1000).format('MMM D, YYYY')

                return `You will be ${proratedAmount>0?'charged':'credited'} a prorated amount of <b>$${Math.abs(proratedAmount)}</b> for the <b>${proratedPeriod} days</b> remaining of your current billing period. Your next regular payment of <b>$${regularAmount}</b> will be due on <b>${nextRegularPaymentDue}</b>.`
            }

            return ''
           
        }
    }
}
</script>